import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom'

import { LiveChatWidget } from '@livechat/widget-react'

import { useAppDispatch, useAppSelector } from './store'

import ReactGA from 'react-ga4'

import api from './api'

import { clearLoginData } from './store/authSlice'
import { InitialStateType, setLiveChatVisibility } from './store/liveChatSlice'

import { useTranslation } from 'react-i18next'
import ComingSoon from './components/v2/buttons/ComingSoon'
import i18n from './i18n'
import { DayjsLocalManager } from './screens/hooks/DayjsLocalManager'
import { AppState, setIsProduction } from './store/appSlice'

import Loader from './components/Loader'
import AppRoutes from './routes'

// user route
export const UserPrivateRoute = ({ children }: { children: ReactNode }): JSX.Element | null => {
  const auth = useAppSelector((data) => data.auth)
  if (auth.userLoading) {
    return <Loader />
  }

  if (auth.token && auth.user && ['User'].includes(auth.user.userType)) {
    return <>{children}</>
  }

  return <Navigate replace to="/login" />
}
//
const GOOGLE_TRACKING_ID = 'G-VLBVDQWE11'
ReactGA.initialize(GOOGLE_TRACKING_ID)
//
const App = () => {
  // update html lang att
  document.documentElement.lang = i18n?.language
  //
  const { t } = useTranslation()
  //
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.location.pathname.replace(/\//g, ' ').replace(/-/g, ' '),
    })
    return () => {}
  }, [])
  //
  const auth = useAppSelector((data) => data.auth)
  //
  const { lang } = useAppSelector((root: { app: AppState }) => root.app)
  //
  const dispatch = useAppDispatch()
  //
  const getUsurByTokenCallback = useCallback(() => {
    // TODO this gets called twice, even if `auth.token`'s value doesn't change
    if (auth.token) {
      const loadProfile = async () => {
        const {
          // status,
          // data,
          error: loadProfileError,
          //refetch
        } = await dispatch(api.endpoints.profile.initiate({ token: auth.token }))
        if (loadProfileError) {
          // console.log('login via token failed', { loadProfileError })
          dispatch(clearLoginData())
        }
      }
      loadProfile()
    }
  }, [auth.token, dispatch])
  //
  useEffect(() => {
    getUsurByTokenCallback()

    return () => {}
  }, [getUsurByTokenCallback])
  //
  //
  const { licenseKey, visibility, group } = useAppSelector((data: { liveChat: InitialStateType }) => data.liveChat)
  //
  useEffect(() => {
    DayjsLocalManager(lang === 'ge' ? 'ka' : lang)
    //
  }, [dispatch, lang])
  //
  //
  const setIsProductionCallback = useCallback(() => {
    //
    const hostname = window.location.hostname
    const isProduction = hostname === 'frani.com' || hostname === 'www.frani.com' ? true : false
    //
    dispatch(setIsProduction(isProduction))
  }, [dispatch])
  //
  useEffect(() => {
    setIsProductionCallback()
    return () => {}
  }, [setIsProductionCallback])
  //
  //
  const PathName = window.location.hostname
  //
  // pin code
  const [pinCode] = useState<string | null>(localStorage.getItem('pin'))
  //
  //
  return (
    <>
      {(pinCode === '78651' || pinCode === null) && PathName === 'dev.frani.com' ? (
        <BrowserRouter
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
          }}
        >
          <Routes>
            <Route path="*" element={<ComingSoon title={t('pinTitle')} message={t('pinDescription')} />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <>
          <LiveChatWidget
            onVisibilityChanged={(event) => dispatch(setLiveChatVisibility(event.visibility))}
            license={licenseKey}
            group={group}
            visibility={visibility}
          />
          <AppRoutes />
        </>
      )}
    </>
  )
}

export default App
