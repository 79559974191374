import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/styles/Colors'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
// import { Scrollbar } from '../assets/styles/GlobalStyle'

const Loader: React.FC<{ text?: string; style?: React.CSSProperties; horizontal?: boolean }> = ({
  text = '',
  style,
  horizontal = false,
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper $horizontal={horizontal} style={style}>
      <Helmet>
        <title>{t('frani')}</title>
      </Helmet>
      {/* <Scrollbar hideScroll={true} /> */}
      <div className="container">
        <svg className="circle-svg" height="100" width="100">
          <circle cx="50" cy="50" r="25"></circle>
        </svg>
        {text && <p>{text}</p>}
      </div>
    </Wrapper>
  )
}
//
const Wrapper = styled.div<{ $horizontal: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000000000000000000000000; // for live chat widget
  height: 100vh;
  width: 100vw;
  background-color: ${Colors.white};
  display: grid;
  place-items: center;
  user-select: none;
  pointer-events: none;
  text-align: center;
  /*  */
  p {
    font-size: 18px;
    color: ${Colors.primaryBlack};
    margin-bottom: 0px;
  }
  /*  */
  --radius: 25;
  --PI: 3.14159265358979;
  --circumference: calc(var(--PI) * var(--radius) * 2px);
  /*  */
  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    /*  */
    flex-direction: ${({ $horizontal }) => ($horizontal ? 'unset' : 'column')};
    justify-content: ${({ $horizontal }) => ($horizontal ? 'center' : 'unset')};
    /*  */
  }
  .circle-svg {
    background: #fff;
  }
  .circle-svg circle {
    stroke: ${Colors.primaryRed};
    stroke-width: 4;
    fill: transparent;
    transform-origin: center;
    stroke-dasharray: var(--circumference);
    animation: spinner 2s ease-out infinite;
  }

  @keyframes spinner {
    from {
      stroke-dashoffset: var(--circumference);
      stroke: ${Colors.primaryRed};
      transform: rotateZ(0deg);
    }
    to {
      stroke-dashoffset: calc(var(--circumference) * -1);
      stroke: ${Colors.primaryRed};
      transform: rotateZ(720deg);
    }
  }
  /*  */
`
//
export default Loader
