import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FraniIconWIthLine from '../../../assets/v2/JSX/FraniIconWIthLine'
import Colors from '../../../assets/styles/Colors'
import Responsive from '../../../assets/config/Responsive'
import OtpInput from 'react-otp-input'
//
//
const ComingSoon: React.FC<{
  title: string
  message: string
}> = ({ title, message }) => {
  //
  const [otp, setOtp] = useState<string>('')
  //
  const [hasError, setHasError] = useState(false)
  //
  useEffect(() => {
    //
    if (otp === '78651') {
      localStorage.setItem('pin', 'unlock')
      window.location.reload()
    }
    //
    if (otp && otp.length === 5 && otp !== '78651') {
      setHasError(true)
    } else {
      setHasError(false)
    }
    //
    return () => {}
  }, [otp])
  //
  //
  return (
    <Wrapper>
      <FraniIconWIthLineStyle />
      <Fields $hasError={hasError}>
        <Title>{title}</Title>
        <Description>{message}</Description>
        <OtpInput
          inputType="number"
          inputStyle={'otp-field-class'}
          value={otp}
          onChange={setOtp}
          numInputs={5}
          renderInput={(props) => <input {...props} />}
        />
      </Fields>
    </Wrapper>
  )
}
//

const Fields = styled.div<{ $hasError: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3;

  .otp-field-class {
    font-size: 30px;
    margin-top: 40px;
    width: 56px !important;
    height: 56px !important;
    outline: none !important;
    border: ${({ $hasError }) =>
      $hasError === true ? `1px solid ${Colors.primaryRed}` : `1px solid ${Colors.secondaryGrey}`};
    border-radius: 4px;
    background-color: transparent;
    box-shadow: 'unset';
    color: ${({ $hasError }) => ($hasError === true ? `${Colors.primaryRed}` : `${Colors.primaryBlack}`)};

    &:not(:last-child) {
      margin-right: 12px;
    }

    ::selection {
      background-color: transparent;
      color: ${({ $hasError }) => ($hasError === true ? `${Colors.primaryRed}` : `${Colors.primaryBlack}`)};
    }

    ${Responsive.mobile} {
      margin-top: 32px;
      width: 48px !important;
      height: 48px !important;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`
const FraniIconWIthLineStyle = styled(FraniIconWIthLine)`
  user-select: none;
  pointer-events: none;
  position: absolute;
  bottom: 125px;
  left: -50px;

  ${Responsive.laptop} {
    bottom: 125px;
    left: -15%;
  }

  ${Responsive.tablet} {
    bottom: 125px;
    left: -30%;
  }

  ${Responsive.mobile} {
    left: -90px;
    bottom: 125px;
    width: 100%;
  }
`
const Title = styled.h5`
  font-size: 36px;
  color: ${Colors.primaryBlack};

  ${Responsive.mobile} {
    font-size: 18px;
  }
`
const Description = styled.p`
  width: 70%;
  text-align: center;
  font-size: 18px;
  color: ${Colors.nevadaGrey};
  margin-bottom: 0px;

  ${Responsive.mobile} {
    font-size: 14px;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`
//
export default ComingSoon
